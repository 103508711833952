export const navLinks = [
  {
    id: "",
    title: "Home",
  },
  {
    id: "qr",
    title: "QR",
  },
  {
    id: "nuxx",
    title: "Nuxx",
  },
  {
    id: "github",
    title: "Github",
  },
];

export const sitemap = [
  {
    title: "Bolleke Pijlke",
    src: "https://dropping.nuxx.be",
    content:
      "Design your route by drawing it on your mobile phone!\n'Bolleke Pijlke' style!",
    img: "dropping.png",
  },
  {
    title: "Pourquoi Pas",
    src: "https://pourquoipas.nuxx.be",
    content: `Website for the incredible artist Pourquoi Pas!\n Go check it out!`,
    img: "pourquoipas.png",
  },
];
