import { BrowserRouter } from "react-router-dom";
import Navigator from "./Routing/Navigator";
import { Home } from "./Views/Home";
import { ThemeProvider } from "./ThomeProvider";

export default function App() {
  return (
    // <Providres>
    <BrowserRouter>
      <Navigator />
    </BrowserRouter>
    // </Providers>
    // <Home />
  );
}
