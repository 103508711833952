import { Box, Button, Text } from "@radix-ui/themes";
import { BarChartIcon, ImageIcon } from "@radix-ui/react-icons";

export function Downloads() {
  const handleIOS = () => {
    window.location.href = "/KSAios.zip";
  };
  const handleAndroid = () => {
    window.location.href = "/KSAandroid.zip";
  };
  return (
    <Box className="flex flex-col w-screen justify-center h-screen">
      <Text size="7" className="place-self-left m-2">
        Download voor:
      </Text>
      <Box className="flex justify-center mt-3">
        <Box className=" mr-2 flex-1">
          <Button size="4" onClick={handleAndroid} className="w-full">
            Android
          </Button>
        </Box>
        <Box className=" ml-2 flex-1">
          <Button size="4" onClick={handleIOS} className="w-full">
            Apple
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
