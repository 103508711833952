import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import { navLinks } from "../constants";
import React from "react";
import { Home } from "../Views/Home";
import { Box, Flex, Tabs, Text } from "@radix-ui/themes";
import { QR } from "../Views/qr";
import { Github } from "../Views/Github";
import { Downloads } from "../Views/Downloads";

export default function Navigator() {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="QR" element={<QR />} />
          <Route path="Nuxx" element={<Nuxx />} />
          <Route path="Github" element={<Github />} />
          <Route path="Downloads" element={<Downloads />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Box>
  );
}

function Layout() {
  const location = useLocation();
  console.log(location);
  return (
    <Box className="min-h-screen flex">
      <Box className="flex flex-col w-full">
        <Box className="lg:px-24 px-2 lg:pt-4 pt-2">
          {location.pathname !== "/downloads" && (
            <Tabs.Root className="">
              <Tabs.List
                className=" md:justify-end justify-center border-0"
                size="2"
              >
                {navLinks.map((nav, index) => (
                  <Tabs.Trigger key={index} value={nav.id} className="">
                    <Link
                      className="md:mx-[-15px] md:p-2 md:px-[15px]"
                      to={`/${nav.id}`}
                      replace
                    >
                      <Box className="text-lg md:text-xl">{nav.title}</Box>
                    </Link>
                  </Tabs.Trigger>
                ))}
              </Tabs.List>
            </Tabs.Root>
          )}
        </Box>
        <Box className="h-full">
          <Outlet />
        </Box>
      </Box>
    </Box>
    // <Box className="mx-10">
    //   <nav className="w-full flex pt-6 pb-2 justify-between items-center navbar">
    //     <ul className="list-none sm:flex hidden justify-start items-center flex-1">
    //       {navLinks.map((nav, index) => (
    //         <li
    //           key={nav.id}
    //           className={`font-poppins font-normal cursor-pointer text-[18px] mr-10 ${
    //             active === nav.title ? "text-white" : "text-gray-400"
    //           }
    //           `}
    //           onClick={() => setActive(nav.title)}
    //         >
    //           <Link to={`/${nav.id}`}>{nav.title}</Link>
    //         </li>
    //       ))}
    //     </ul>

    //     <Box className="sm:hidden flex flex-1 justify-end items-center">
    //       <Box
    //         className={`${
    //           !toggle ? "hidden" : "flex"
    //         } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
    //       >
    //         <ul className="list-none flex justify-end items-start flex-1 flex-col">
    //           {navLinks.map((nav, index) => (
    //             <li
    //               key={nav.id}
    //               className={`font-poppins font-medium cursor-pointer text-[16px] "mb-4"}`}
    //               // onClick={() => setActive(nav.title)}
    //             >
    //               <Link to={`/${nav.id}`}>{nav.title}</Link>
    //             </li>
    //           ))}
    //         </ul>
    //       </Box>
    //     </Box>
    //   </nav>
    //   <hr className="mx-[-10px]" />
    //   <Box className="mt-4 flex ">
    //     <Outlet />
    //   </Box>
    // </Box>
  );
}

function About() {
  return (
    <Box>
      <h2>About</h2>
    </Box>
  );
}

function Nuxx() {
  return (
    <Box>
      <h2>Nuxx</h2>
    </Box>
  );
}

function NoMatch() {
  return (
    <Box>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </Box>
  );
}
