import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { Box, Button, IconButton, Text, TextField } from "@radix-ui/themes";
import React from "react";
import QRCode from "react-qr-code";

export function Github() {
  const handleClick = () => {
    window.open("https://github.com/nuxxian", "_blank"); // Open in a new tab
  };
  return (
    <Box className="flex w-screen justify-center h-screen">
      <IconButton size="3" onClick={handleClick} className="flex-1">
        <GitHubLogoIcon width={35} height={35} />
      </IconButton>
    </Box>
  );
}
