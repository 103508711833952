import { Box, Heading } from "@radix-ui/themes";
import { BarChartIcon, ImageIcon } from "@radix-ui/react-icons";
import { sitemap } from "../constants";
import { type } from "os";
import React from "react";

export function Home() {
  return (
    <Box className="w-screen h-full lg:px-24 px-2">
      <Box className="flex-col flex h-full border-0">
        <Box className="flex border-0 flex-col">
          <Box className="pt-6 pb-0">
            <Heading size="9" weight="regular">
              Nuxx
            </Heading>
          </Box>
          <Box></Box>
          <Box className="">
            <Heading size="8" weight="light">
              HUB of HEAD
            </Heading>
          </Box>
          <Box />
        </Box>
        <Box className="border-0 flex-1 h-full justify-center items-center">
          <Box className="flex border-0 h-full justify-center items-center">
            <Carousel />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

type Site = {
  title: string;
  src: string;
  content: string;
  img: string;
};

function Carousel() {
  return (
    <Box className="flex-wrap flex w-full justify-around lg:px-16">
      {sitemap.map((site, i) => (
        <Box className="xl:w-1/4 md:w-1/3 w-2/3 aspect-square space-between lg:p-7 p-2">
          <CarouselItem site={site} />
        </Box>
      ))}
    </Box>
  );
}

function CarouselItem({ site }: { site: Site }) {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => (window.location.href = site.src)}
    >
      {isHovered && (
        <div
          className="whitespace-pre-line px-3 absolute inset-0 bg-black bg-opacity-50 flex text-center justify-center items-center z-10 lg:text-2xl text-xl text leading-10"
          // style={{ fontFamily: "SmoochBold" }}
        >
          {site.content}
        </div>
      )}
      <img
        src={`./assets/img/${site.img}`}
        alt={site.title}
        className={`z-0 w-full h-full object-cover ${
          isHovered ? "filter grayscale" : ""
        }`}
      />
    </div>
  );
}
