import { Box, Button, Text, TextField } from "@radix-ui/themes";
import React from "react";
import QRCode from "react-qr-code";

export function QR() {
  const [QR, setQR] = React.useState("");
  const [name, setName] = React.useState("");
  const downloadQR = () => {
    const svgElement = document.getElementById("qr");

    if (svgElement instanceof SVGElement) {
      // Serialize the SVG element to XML
      const svgXML = new XMLSerializer().serializeToString(svgElement);

      // Create a data URL from the SVG XML
      const pngUrl = `data:image/svg+xml;base64,${btoa(svgXML)}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "QRcode.png";
      if (name !== "") {
        downloadLink.download = name + ".png";
      }
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };
  return (
    <Box className="flex w-screen items-center flex-col">
      <Box className="w-fill mb-5 flex items-center">
        <Text className="pr-4">Content of QR?</Text>
        <TextField.Input
          size="3"
          placeholder="Username"
          value={QR}
          onChange={(e) => setQR(e.target.value)}
          className=""
        />
      </Box>
      <QRCode
        id="qr"
        size={512}
        value={QR}
        viewBox={`0 0 256 256`}
        version="H"
      />
      <Box className="w-fill mt-5 flex items-center">
        <Text className="pr-2">Name of file:</Text>
        <TextField.Input
          size="3"
          placeholder="Username"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className=""
        />
        <Box className="pl-4">
          <Button onClick={downloadQR}>Download!</Button>
        </Box>
      </Box>
    </Box>
  );
}
